import { makeStyles } from '@mui/styles';
import * as React from "react";
import { useState } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    ImageInput,
    SelectInput,
    ImageField,
    ReferenceArrayInput,
    ReferenceInput,
    SelectArrayInput,
    FileInput,
    FileField,
    required,
    number,
    DateTimeInput,
    useGetList,
    TranslatableInputs,
    useLocaleState
} from 'react-admin';
import { DisplayStatus } from '../../shared/enums/display-status.enum';
import { TimePeriod } from '../../shared/interfaces/timePeriod.interface';
import GetTimePeriodRange from '../../shared/services/timePeriod.services';
import { validateTimePeriod } from '../../shared/services/validateTimePeriod.service';
import { locales } from  '../../i18n/languges'
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues } from 'react-hook-form';


const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

export const PathCreate = () => {
    const classes = useStyles();
    const [locale] = useLocaleState();
    const timePeriods = useGetList<TimePeriod>(
        "timeperiods",
        {
            pagination: { page: 1, perPage: 100 },
            sort: { field: "id", order: "ASC" },
        },
    );
    const [displayValue, setDisplayValue] = useState(DisplayStatus.Display);
    const [selectedTimePeriodId, setSelectedTimePeriodId] = useState<number>(0);
    const selectedTimePeriod = GetTimePeriodRange(
        selectedTimePeriodId,
        timePeriods.data || {}
    );
    const displayOptions = Object.keys(DisplayStatus).map(key => ({
        id: DisplayStatus[key],
        name: `resources.enums.displayStatus.${key}`,
    }));

    const schema = yup
        .object()
        .shape({
            name: yup.object().shape({
                en: yup.string().required()
        })
        .required()
    });
    const resolver = yupResolver<FieldValues>(schema);

    return (
        <Create>
            <SimpleForm sanitizeEmptyValues resolver={resolver}>
                <TranslatableInputs fullWidth={true} locales={locales} sx={{marginBottom: '2rem'}} >
                    <TextInput source="name" />
                    <TextInput source="start"/>
                    <TextInput source="end"/>
                    <TextInput source="description" rows={8} multiline={true} fullWidth={true} />
                </TranslatableInputs>
                <TextInput source="number" validate={[required()]}/>
                <TextInput source="length" validate={[required(), number()]}/>
                <DateTimeInput source="groomedAt" defaultValue={new Date()} />
                <SelectInput source="difficulty" validate={[required()]} choices={[
                    { id: 'veryEasy', name: 'resources.paths.fields.veryEasy' },
                    { id: 'easy', name: 'resources.paths.fields.easy' },
                    { id: 'medium', name: 'resources.paths.fields.medium' },
                    { id: 'hard', name: 'resources.paths.fields.hard' },
                ]} />
                <SelectInput source={`status.${locale}`} label="resources.paths.fields.status" validate={[required()]} defaultValue={'open'} choices={[
                    { id: 'open', name: 'resources.paths.fields.open' },
                    { id: 'closed', name: 'resources.paths.fields.closed' },
                    // { id: 'freshlyGroomed', name: 'freshly groomed' },
                ]} />
                <ReferenceInput source="pathCategoryId" reference="pathcategories">
                    <SelectInput optionText={`name.${locale}`} label="resources.paths.fields.category" validate={[required()]}/>
                </ReferenceInput>
                <ReferenceArrayInput source="area_ids" reference="areas">
                    <SelectArrayInput optionText={`name.${locale}`} label="resources.paths.fields.areas" validate={[required()]} defaultValue={[]} />
                </ReferenceArrayInput>
                <ReferenceArrayInput source="poi_ids" reference="pois">
                    <SelectArrayInput label="resources.paths.fields.relatedPois" optionText={record => {
                        if (record.areas.length > 0) {
                            return `${record.name[locale]} (${record.areas.map(area => area.name[locale]).join(', ')})`;
                        }
                        return `${record.name.locale}`;
                    }} defaultValue={[]} />
                </ReferenceArrayInput>
                <ReferenceArrayInput source="tag_ids" reference="tags">
                    <SelectArrayInput optionText={`name.${locale}`} label="resources.paths.fields.tags" />
                </ReferenceArrayInput>
                <FileInput source="gpx" accept=".gpx" validate={[required()]}>
                    <FileField source="src" title="title" />
                </FileInput>
                <SelectInput
                    source="display"
                    choices={displayOptions}
                    defaultValue={DisplayStatus.Display}
                    onChange={(e) => setDisplayValue(e.target.value as DisplayStatus)}
                    validate={[required()]}
                ></SelectInput>
                {displayValue === DisplayStatus.Scheduled && (
                    <ReferenceInput
                        source="timePeriodId"
                        reference="timeperiods"
                        onChange={(e) => setSelectedTimePeriodId(e.target.value)}
                    >
                        <SelectInput
                            optionText="name"
                            label="Time period"
                            helperText={selectedTimePeriod}
                            validate={[validateTimePeriod, required()]}
                        ></SelectInput>
                    </ReferenceInput>
                )}
                <ImageInput source="medias" accept="image/*" multiple={true}>
                    <ImageField source="src" title="title" />
                </ImageInput>
                <TextInput source="socialMedia.instagramUrl" label="Instagram url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.facebookUrl" label="Facebook url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.linkedinUrl" label="Linkedin url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.twitterUrl" label="Twitter url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.vimeoUrl" label="Vimeo url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.youtubeUrl" label="Youtube url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.tripadvisorUrl" label="Tripadvisor url" formClassName={classes.inlineBlock}/>
            </SimpleForm>
        </Create>
    );
}