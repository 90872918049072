import * as React from "react";
import { Fragment } from 'react';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Pagination, BulkDeleteWithConfirmButton, Button, Confirm, Filter, ReferenceInput, SelectInput, TextInput, useNotify, useRefresh, useUnselectAll, useUpdateMany, useTranslate } from 'react-admin';
import { List, Datagrid, TextField, DateField, ArrayField, SingleFieldList, ChipField, useLocaleState } from 'react-admin';

const PathBulkActionButtons = (props: any) => {
    const [selectedIds, setSelectedIds] = React.useState(props.selectedIds);
    const [isActive, setIsActive] = React.useState('');

    const [openStatusConfirm, setOpenStatusConfirm] = React.useState(false);
    const [openGroomedConfirm, setOpenGroomedConfirm] = React.useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll('grooming/paths');
    const translate = useTranslate();

    const [updateManyWithStatus, { isLoading: loadingManyWithStatus }] = useUpdateMany(
        'grooming/paths',
        { 
            ids: selectedIds,
            data: { status: isActive },
        },
        {
            onSuccess: () => {
                refresh();
                notify('Paths updated');
                unselectAll();
            },
            onError: error => notify(`Error: paths not updated with status: "${isActive}".`, {type: 'warning'}),
        }
    );

    const [updateManyAsGroomed, { isLoading: loadingManyAsGroomed }] = useUpdateMany(
        'grooming/paths',
        { 
            ids: selectedIds,
            data: { groomedAt: new Date() },
        },
        {
            onSuccess: () => {
                refresh();
                notify('Paths updated');
                unselectAll();
            },
            onError: error => notify(`Error: paths failed to update groom.`, {type: 'warning'}),
        }
    );

    // const handleClick = () => setOpenStatusConfirm(true);
    const handleStatusDialogClose = () => setOpenStatusConfirm(false);

    const handleGroomedDialogClose = () => setOpenGroomedConfirm(false);

    const handleConfirmForStatus = () => {
        updateManyWithStatus();
        setOpenStatusConfirm(false);
    };

    const handleConfirmForGroomed = () => {
        updateManyAsGroomed();
        setOpenGroomedConfirm(false);
    };

    React.useEffect(() => {
        setIsActive('');
        setSelectedIds(props.selectedIds);
    }, [props.selectedIds, selectedIds]);

    const handleChange = (event: any) => {
        setOpenStatusConfirm(true)
        setIsActive(event.target.value);
    };

    return (
        <Fragment>
            <FormControl variant="standard" style={{ position: 'relative', marginRight: '10px', }}>
                <InputLabel shrink htmlFor="statusFilter">{translate(`resources.grooming/paths.fields.setStatus`)}</InputLabel>
                <Select
                    value={isActive}
                    id="statusFilter"
                    onChange={handleChange}
                    displayEmpty
                >
                    <MenuItem value="" disabled>
                        <em>{translate(`resources.grooming/paths.fields.selectOne`)}</em>
                    </MenuItem>
                    <MenuItem value="open" selected>{translate(`resources.grooming/paths.fields.open`)}</MenuItem>
                    <MenuItem value="closed">{translate(`resources.grooming/paths.fields.closed`)}</MenuItem>
                    {/*<MenuItem value="freshlyGroomed">freshly groomed</MenuItem>*/}
                </Select>
                {/*<FormHelperText>Label + placeholder</FormHelperText>*/}
            </FormControl>
            <Confirm
                isOpen={openStatusConfirm}
                loading={loadingManyWithStatus}
                title="Update paths with status"
                content={`Are you sure you want set the status to "${isActive}" for these items?`}
                onConfirm={handleConfirmForStatus}
                onClose={handleStatusDialogClose}
            />
            <Confirm
                isOpen={openGroomedConfirm}
                loading={loadingManyAsGroomed}
                title="Update paths as groomed"
                content={`Are you sure you want to groom these items?`}
                onConfirm={handleConfirmForGroomed}
                onClose={handleGroomedDialogClose}
            />
            <Button color="primary" variant="outlined" label={translate('resources.grooming/paths.fields.groomed')} onClick={() => setOpenGroomedConfirm(true)} />
            {/*<Button label="Save" onClick={handleClick} />*/}
            {/*<BulkDeleteWithConfirmButton  {...props} />*/}
        </Fragment>
    );
}

const PathFilter = props => {
    const { locale } = props;
    return(
    <Filter {...props}>
        <TextInput source="q" alwaysOn resettable/>
        <ReferenceInput source="filterpath" target="id" reference="grooming/filter/paths" filter={{ filterType: "path"}} alwaysOn>
            <SelectInput variant="filled" optionText={`name.${locale}`}  emptyText="resources.grooming/paths.fields.pathEmptyText"/>
        </ReferenceInput>
        <ReferenceInput source="areas" target="id" reference="grooming/areas" alwaysOn>
            <SelectInput variant="filled" optionText={`name.${locale}`}  emptyText="resources.grooming/paths.fields.areaEmptyText"/>
        </ReferenceInput>
        {/*<ReferenceInput label="Category" source="pathCategoryId" target="id" reference="grooming/pathcategories" allowEmpty={false} alwaysOn resettable>
            <SelectInput optionText="name" />
        </ReferenceInput>*/}
        <SelectInput variant="filled" source="status" emptyText="resources.grooming/paths.fields.statusEmptyText" alwaysOn choices={[
            { id: 'open', name: 'resources.grooming/paths.fields.open' },
            { id: 'closed', name: 'resources.grooming/paths.fields.closed' },
            // { id: 'freshlyGroomed', name: 'freshly groomed' },
        ]} />
        {/*<Button variant="text" onClick={() => props.setFilters({})} label="Clear filters"/>*/}
    </Filter>
)};

const PathPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const rowStyle = (record, index) => {
    const rowCSS = {};
    if (record.status === 'closed') {
        rowCSS['backgroundColor'] = '#ffeeee';
    }
    return rowCSS;
};

export const GroomingPathList = () => {
    const [locale] = useLocaleState();

    return (
        <List
            perPage={25}
            pagination={<PathPagination />}
            filters={<PathFilter locale={locale}/>}
            exporter={false}
            filter={{pathCategoryId: 1}}
        >
            <Datagrid /*rowClick="edit" expand={<GroomingPathEdit/>}*/ rowStyle={rowStyle} bulkActionButtons={<PathBulkActionButtons />}>
                <TextField source="number" />
                <TextField source={`name.${locale}`} />
                <DateField source="groomedAt" options={{ day: '2-digit', hour: '2-digit', minute: '2-digit', month: 'short' }}/>
                <TextField source="status" />
                {/*<TextField source="pathCategory.name" label="Category" sortable={false}/>*/}
                {/*<TextField source="area.name" label="Area" sortable={false}/>*/}

                <ArrayField
                    source="areas"
                    sortable={false}
                >
                    <SingleFieldList linkType={false}>
                        <ChipField color="secondary" source={`name.${locale}`} />
                    </SingleFieldList>
                </ArrayField>

                {/*<TextField source="length" label="Length (km)" />
                <TextField source="difficulty" />*/}
                {/*<ArrayField
                    source="tags"
                    sortable={false}
                >
                    <SingleFieldList linkType={false}>
                        <ChipField clickable={false} source="name" color={'primary'} />
                    </SingleFieldList>
                </ArrayField>*/}
            </Datagrid>
        </List>
    );
}

export default GroomingPathList;
