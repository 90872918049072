import englishMessages from 'ra-language-english';

export default {
    ...englishMessages,
    resources: {
        enums: {
            publishingStatus: {
                draft: 'Draft',
                published: 'Published',
                archived: 'Archived',
                ready: 'Ready'
            },
            headerType: {
                image: 'Image',
                slideshow: 'Slideshow',
                video: 'Video',
            },
            severity: {
                info: 'Info',
                important: 'Important',
                warning: 'Warning',
            },
            mapConnection: {
                None: 'None',
                PointOfInterest: 'Point Of Interest',
                AreaOfInterest: 'Area Of Interest',
            },
            recurrence: {
                None: 'None',
                Daily: 'Daily',
                OnEntrance: 'On Entrance',
            },
            displayStatus:{
                Display: 'Display',
                Hide: 'Hide',
                Scheduled: 'Scheduled',
            }
        },
        menu: {
            dashboard: 'Dashboard',
            users: 'Users',
            appSettings: 'App settings',
            settings: 'Settings',
            terms: 'Terms and conditions',
            timePeriods: 'Time periods',
            startPage: 'Start Page',
            areas: 'Areas',
            information: 'Information',
            tags: 'Tags',
            areaOfInterests: 'Area of Interests',
            categories: 'Categories',
            pointOfInterests: 'Point of Interests',
            paths: 'Paths',
            snowmobiles: 'Snowmobiles',
            recommendations: 'Recommendations',
            subPaths: 'Sub Paths',
            freeRidingAreas: 'Free Riding Areas',
            grooming: 'Grooming',
            groups: 'Groups',
            crossCountryPaths: 'Cross-country Paths',
            snowmobilePaths: 'Snowmobile Paths',
        },
        page: {
            dashboard: {
                welcomeAdmin: 'Welcome admin!',
                welcomeGroomer: 'Welcome groomer!',

            },
        },
        users: {
            setActive: 'Set active',
            selectOne: 'Select One',
            yes: 'Yes',
            no: 'No',
            fields: {
                search: 'Search',
                isActive: 'Is active',
                firstName: 'First Name',
                lastName: 'Last Name',
                email: 'Email',
                phone: 'Phone',
                validatePhone: 'Must be a valid phone number format',
                role: 'Role',
                roles: {
                    user: 'User',
                    admin: 'Admin',
                    groomer: 'Groomer'
                },
                password: 'New password',
                createdAt: 'Created at',
                updatedAt: 'Updated at'
            }
        },
        settings: {
            fields: {
                termsId: 'Terms and conditions',
                summerSeasonId: 'Summer season',
                winterSeasonId: 'Winter season',
                updatedAt: 'Updated at'
            }
        },
        terms: {
            fields: {
                status: 'Status',
                name: 'Name',
                title: 'Title',
                terms: 'Terms',
                createdAt: 'Created at',
                updatedAt: 'Updated at'
            }
        },
        timeperiods: {
            fields: {
                search: 'Search',
                name: 'Name',
                start: 'Start',
                end: 'End'
            }
        },
        areas: {
            fields: {
                search: 'Search',
                name: 'Name',
                createdAt: 'Created at',
                updatedAt: 'Updated at'
            }
        },
        startpages: {
            fields: {
                title: 'Title',
                body: 'Body',
                headerType: 'Header type',
                medias: 'Medias',
                updatedAt: 'Updated at',
                priority: 'Priority',
                name: 'Name',
                entryType: 'Entry type',
            },
            editRows: 'Edit Rows',
            create: 'Create',
            editRowEntries: 'Edit Row Entries',
        },
        information: {
            fields: {
                status: 'Status',
                severity: 'Severity',
                title: 'Title',
                description: 'Description',
                startAt: 'Start at',
                endAt: 'End at',
                buttonText: 'Button text',
                buttonUrl: 'Button url',
                tag_ids: 'Tags',
                mapConnection: 'Map connection',
                recurrence: 'Recurrence',
                createdAt: 'Created at',
                updatedAt: 'Updated at'
            }
        },
        informationtags: {
            fields: {
                search: 'Search',
                name: 'Name',
                createdAt: 'Created at',
                updatedAt: 'Updated at'
            }
        },
        aois: {
            filterText: 'All categories',
            fields: {
                search: 'Search',
                name: 'Name',
                createdAt: 'Created at',
                updatedAt: 'Updated at',
                category: 'Category',
                area_ids: 'Area',
                areas: 'Areas',
                area: 'Area',
                display: 'Display',
                description: 'Description',
                geoJsonFilename: 'Current GeoJson file',
                geojson: 'New Geo-Json file',
                timePeriodId: 'Time period',
                medias: 'Medias'
            }
        },
        aoicategories: {
            fields: {
                isListed: 'Is listed?',
                name: 'Name!',
                pageTitle: 'Page title!',
                createdAt: 'Created at',
                updatedAt: 'Updated at',
                description: 'Description!',
                media: 'Media'
            }
        },
        pois: {
            areaFilterText: 'All areas',
            categoryFilterText: 'All categories',
            fields: {
                area: 'Area',
                areas: 'Areas',
                area_ids: 'Areas',
                category: 'Category',
                name:'Name',
                display: 'Display',
                updatedAt: 'Updated at',
                latitude: "Latitude",
                longitude: 'Longitude',
                elevation: 'Elevation',
                url: 'Url',
                description: 'Description',
                pointOfInterestCategoryId: 'Category',
                timePeriodId: 'Time period',
                medias: 'Medias',
            }
        },
        poicategories: {
            fields: {
                q: 'Search',
                isListed: 'Is listed?',
                name: 'Name',
                pageTitle: 'Page title',
                createdAt: 'Created at',
                updatedAt: 'Updated at',
                description: 'Description',
                media: 'Media'
            }
        },
        paths: {
            fields: {
                setStatus: 'Set status',
                selectOne: 'Select One',
                groomed: 'Groomed',
                updateStatusTitle: 'Update paths with status',
                openStatusContent: 'Are you sure you want set the status to "open" for these items?',
                closedStatusContent: 'Are you sure you want set the status to "closed" for these items?',
                updateGroomedStatusTitle: 'Update paths as groomed',
                updateGroomedStatusContent: 'Are you sure you want to groom these items?',
                q: 'Search',
                area: 'Area',
                areas: 'Areas',
                areasEmptyText: 'All areas',
                category: 'Category',
                display: 'Display',
                categoryEmptyText: 'All categories',
                status: 'Status',
                statusEmptyText: 'All statuses',
                number: 'Number',
                name: 'Name',
                groomedAt: 'Groomed at',
                tag_ids: 'Tag',
                length: 'Length (km)',
                start: 'start name',
                end: 'end name',
                lastGPXFilename: 'Current GPX file',
                difficulty: 'Difficulty',
                veryEasy: 'very easy',
                easy: 'easy',
                medium: 'medium',
                hard: 'hard',
                open: 'open',
                closed: 'closed',
                relatedPois: 'Related Pois',
                tags: 'Tags',
                gpx: 'GPX file',
                description: 'Description',
                timePeriodId: 'Time period',
                medias: 'Medias',
                createdAt: 'Created at',
                updatedAt: 'Updated at',
            }
        },
        pathcategories: {
            fields: {
                name: 'Name',
                pageTitle: 'Page title',
                description: 'Description',
                media: 'Media',
                createdAt: 'Created at',
                updatedAt: 'Updated at',
            }
        },
        tags: {
            fields: {
                q: 'Search',
                name: 'Name',
                createdAt: 'Created at',
                updatedAt: 'Updated at',
            }
        },
       'grooming/filter/paths': {
            fields: {
                q: 'Search',
                name: 'Name',
                filterType: 'Group Type',
                crossCountry:'Cross-country skiing', 
                snowmobile: 'Snowmobile',
                path_ids: 'Paths',
                subPath_ids: 'Snowmobile Sub Paths',
                createdAt: 'Created at',
                updatedAt: 'Updated at',
            }  
        },
        'grooming/paths': {
            fields: {
                setStatus: 'Set status',
                selectOne: 'Select One',
                groomed: 'Groomed',
                q: 'Search',
                filterpath: 'Group',
                pathEmptyText: 'All groups',
                areas: 'Area',
                areaEmptyText: 'All areas',
                status: 'Status',
                statusEmptyText: 'All statuses',
                open: 'open',
                closed: 'closed',
                number: 'Number',
                name: 'Name',
                groomedAt: 'Groomed at',
            }
        }
    },
};